.pulse {
    @apply
    absolute
    rounded-full;
    width: 80vh;
    height: 80vh;
    z-index: -1;
    bottom: -8vh;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0.9);
    animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(241, 241, 241, 0.9);
    }
    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(241, 241, 241, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0  rgba(241, 241, 241, 0);
    }
  }

@keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(241, 241, 241, 0.9);
      box-shadow: 0 0 0 0 rgba(241, 241, 241, 0.9);
    }
    70% {
        -moz-box-shadow: 0 0 0 30px  rgba(241, 241, 241, 0);
        box-shadow: 0 0 0 30px  rgba(241, 241, 241, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0  rgba(241, 241, 241, 0);
        box-shadow: 0 0 0 0  rgba(241, 241, 241, 0);
    }
}