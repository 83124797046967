.course-content {
    ul {
        @apply
        list-disc
        pl-4
        text-sm
        mb-8;
        li {
            @apply
            mb-4;
        }
    }
    em {
        @apply
        text-sm;
    }
    strong {
        @apply
        font-medium;
    }
    figure {
        @apply
        inline-block
        mr-4
        mb-4;
    }
}