.accordion {
    &__entry {
        overflow: hidden;
        max-height: 0;
        transition: all 0.4s linear;
        padding-bottom: 0;
        &.-active {
            max-height: 300px;
            padding-bottom: 2rem;
        }
    }
}
.accordion__heading {
    &:after {
        @apply
        text-lg
        text-primary-300;
        font-family: "Font Awesome 5 Pro"; 
        font-weight: 900; 
        content: "\f0d7";
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        right: 0;
    }
    &.-active {
        &:after {
            @apply
            text-secondary-500;
            content: "\f0d8";
        }
    }
}