.link-underline {
    a {
      @apply
         relative
         inline-block;
      &:before {
         @apply
         absolute
         bg-secondary-500
         w-0
         left-0;
         content: '';
         bottom: -.11759rem;
         height: .05rem;
          transition: all 250ms ease-in-out;
      }
      &:active:before, &:hover:before {
         @apply
         w-full;
      }
      span {
          @apply
          inline-block;
          transition: all 250ms ease-in-out;
      }
      &:hover {
         @apply
         no-underline;
      }
    }
 }
