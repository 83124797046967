.hero-form {

    .form__label {
        @apply text-xs;
    }

    .form__field {
        @apply text-sm border-none p-2;
        background: rgba(68, 68, 68, 0.8);
    }

    .button-primary {
        @apply text-sm;
    }

    .ff-form-success {
        @apply mx-2 mb-5 text-white;
    }
}
