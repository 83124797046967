.scrollDown {
    position: absolute;
    bottom: 6rem;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #000;
    font : normal 400 20px/1 'Josefin Sans', sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;
  }
  .scrollDown:hover {
    opacity: .5;
  }
  .scrollDown a span {
    position: absolute;
    top: 0;
    left: 50%;
    width: rem(20);
    height: rem(32);
    border: 2px solid #000;
    border-radius: rem(50);
    box-sizing: border-box;
  }
  .scrollDown a span::before {
    position: absolute;
    top: rem(4);
    left: 50%;
    content: '';
    width: rem(4);
    height: rem(4);
    margin-left: rem(-2);
    background-color: #000;
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
  }
  @-webkit-keyframes sdb10 {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb10 {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }