.carousel-wrapper {
    .flickity-slider {
        @apply
        bg-gray-200;
        margin-left: -1px;
    }
    .carousel-cell {
        @apply
        opacity-100
        bg-gray-200;
    }
}
.flickity-viewport {
    transition: height 0.2s;
}
.slideshow-text_wrapper {
    .flickity-page-dots {
        bottom: 0.9375rem;
    }
}


@screen lg {
    .carousel-cell {
        @apply
        opacity-25;
        transition: all 250ms ease-in-out;
        &.is-selected {
            @apply
            opacity-100
        }
    }
}

.slideshow__pagination-item {
    &.-active {
        @apply
        border-secondary-500
        font-normal;
    }
}
@screen sm {
    .slideshow-text_wrapper {
        .flickity-page-dots {
            @apply
            hidden;
        }
    }
    .hero-gallery {
        .flickity-viewport {
            @apply
            overflow-visible;
        }
    }
}


:root {
    .slideshow {
        &--white-dots {
            .flickity-page-dots {
                bottom: rem(-25);
                @screen lg {
                    bottom: rem(-60);
                }
            }
            .dot {
                @apply
                    bg-white;
            }
        }
    }
}
