.list-points {
    ul {
    @apply
    text-lg;
        li {
            @apply
            pl-10
            relative
            leading-snug
            mb-4;
            &:before {
                @apply
                absolute
                bg-primary-200
                w-4
                left-0
                mt-4;
                content: '';
                height: rem(2);
            }
        }
    }
}

@screen sm {

    .list-points {
        ul {
        @apply
        text-xl;
        }
    }

}