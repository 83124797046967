// Body

html,
body {
   @apply
      text-sm
      text-gray-900
      font-sans-primary
      leading-relaxed
      font-light;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility;
}

@screen sm {
   html,
   body {
      @apply
         text-base
         leading-loose
   }
}

// Transition

.transition {
    transition: all 250ms ease-in-out;
}

// Links

a {
   @apply

   text-secondary-500;
   &:hover {
      @apply
      underline;
   }
}
.site-footer-links a {
   @apply
   text-gray-900;
      &:hover {
      @apply
      no-underline
      text-secondary-500;
   }
}
.form-links a {
   @apply
   text-white;
}

// Typography

h1, h2, h3, h4, h5, h6 {
   @apply
   text-gray-900
   mb-8
   leading-tight;
}

h1 {
   @apply
   text-3xl
}
h2 {
   @apply
   text-3xl
   font-light
   tracking-wider;
}
h3 {
   @apply
   text-2xl;
}
h4 {
   @apply
   text-xl
   tracking-wide;
}
h5 {
   @apply
   text-lg
   mb-5;
}
@screen sm {
   h3 { @apply text-3xl; }
   h4 { @apply text-2xl; }
   h5 { @apply text-xl; }
}
@screen lg {
   h1 { @apply text-4xl; }
}
@screen xl {
   h2 { @apply text-4xl; }
}

p {
   @apply mb-8;
   &:last-of-type {
      @apply mb-0;
   }
}


// Selection

::selection {
   @apply
      text-white
      bg-primary-500;
}

// FOUT fix

.wf-loading {
   @apply invisible;
}

.wf-active,
.wf-inactive {
   @apply visible;
}
