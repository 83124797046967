.header-email {
    transform-origin: 10% 10%;
    left: 0;
    bottom: 1rem;
}
@screen lg {
    .header-email {
        left: 2rem;
        bottom: 3rem;
    }
 }

 .back-to-top {
    transform-origin: 50% 0%;
    bottom: 10rem;
    right: 0
}
@screen lg {
    .back-to-top {
        right: 1rem;
    }
 }