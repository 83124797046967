.list {
    &--tick {
        li {
            @apply 
            relative
            pl-8
            leading-tight
            mb-3;
            &:before {
                content: '✓';
                @apply
                    absolute
                    text-xl
                    font-bold
                    text-secondary-500
                    left-0;
                    top: -5px;
            }
        }
        &.white {
            li {
                &:before {
                    @apply
                        text-white;
                }
            }
        }
    }
}