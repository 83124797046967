table {
    @apply
    mb-8;
    td {
        @apply
        border
        border-gray-300
        p-4
        text-sm
        leading-relaxed;
        font-size: .6rem;
    }
}
@screen sm {
    table {
        td {
            @apply
            text-sm;
        }
    }
}
