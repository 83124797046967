.menu {
    &__burger {
        i {
            &:last-child {
                @apply
                hidden;
            }
            &:first-child {
                @apply
                block;
            }
        }
        &.-active {
            i {
                &:first-child {
                    @apply
                    hidden;
                }
                &:last-child {
                    @apply
                    block;
                }
            }
        }
    }
    &__object {
        @apply 
            pointer-events-none
            h-screen
            w-screen;
            transform: translateX(100%);
            transition: all 1000ms cubic-bezier(0.9, 0, 0.175, 1);
        &.-active {
            @apply 
                pointer-events-auto;
                transform: translateX(0%);
            .menu__text {
                opacity: 1;
                transition-delay: 600ms;
            }
            .logo {
                opacity: 1;
                transition-delay: 600ms;
            }
        }
        .menu__text {
            transition: ease;
            transition-delay: 4000ms;
            opacity: 0;
            transition: all 1000ms cubic-bezier(0.9, 0, 0.175, 1);
        }
        .logo {
            transition: ease;
            transition-delay: 2000ms;
            opacity: 0;
            transition: all 1000ms cubic-bezier(0.9, 0, 0.175, 1);
        }
    }
}


