.form {
   &__label {
      @apply
      font-normal
      text-white;
   }
   &__field {
      @apply
         text-white
         block
         appearance-none
         w-full
         border
         border-gray-200
         px-3
         py-3
         shadow
         leading-tight
         rounded-none;
         background-color: rgba(247, 250, 252, 0.05);
         transition: all 250ms ease-in-out;
      &:focus {
         @apply
            outline-none
            shadow-outline;
      }
      @include hover {
         @apply border-white;
      }
   }
   &__field-container {
      @apply relative;
      &--select {
         &:before {
            @apply
               absolute
               w-0
               h-0
               pointer-events-none
               right-0
               mr-5;
               border-left: 5px solid transparent;
               border-right: 5px solid transparent;
               border-top: 5px solid #fff;
            content: '';
            top: 45%;
         }
      }
   }
   &__notification {
      @apply
         pb-8
         text-white;
   }
   &__pages {
   }
}
.lightbox--form {
   .button-primary {
      @apply
         border-2
         border-white;
   }
}
:root {
   ::placeholder {
       @apply
           text-gray-200;
   }
   :-ms-input-placeholder {
       @apply
           text-gray-200;
   }
   ::-ms-input-placeholder {
       @apply
           text-gray-200;
   }
}
