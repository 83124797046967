.hover-image-block {
    @apply
    absolute
    right-0
    w-3/5
    z-10
    pointer-events-none
    mt-4
    opacity-0;
    content: "";
    transition: all 250ms ease-in-out;
}
.hover-colour-block {
    &:before {
        @apply
        bg-secondary-200
        absolute
        bottom-0
        left-0
        w-full
        h-0;
        content: "";
        z-index: -1;
        transition: all 250ms ease-in-out;
    }
    &:hover:before {
        height: 40%;
    }
    &:hover .hover-image-block {
        @apply
        opacity-100;
    }
}
.header-main-image {
    @apply
    hidden;
}
@screen md {
    .header-main-image {
        @apply
        block
        absolute
        z-10
        pointer-events-none
        opacity-0
        left-0;
        content: "";
        transform: translate(-10%, -63%);
        z-index: -1;
        width: 125%;
        transition: all 250ms ease-in-out;
    }
    .header-main-links {
        &:hover .header-main-image {
            @apply
            opacity-100;
        }
    }
}

