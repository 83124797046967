blockquote {
    @apply
    my-4
    italic
    border-l-4
    border-solid
    border-secondary-400
    pl-4
    text-gray-700;
}
@screen sm {
    blockquote {
        @apply
        ml-8
        mr-4
        my-8
    }
}