.countdown {

    &--light {
        @apply bg-white text-black;

        h1, h2, h3, h4, h5, h6 {
            @apply text-black;
        }
    }

    &--dark {
        @apply bg-black text-white;

        h1, h2, h3, h4, h5, h6 {
            @apply text-white;
        }
    }

    &--green {
        @apply bg-green-500 text-white;

        h1, h2, h3, h4, h5, h6 {
            @apply text-white;
        }
    }
}
