.button {
   @apply
      relative
      inline-block
      text-secondary-500
      font-normal;
   &:before {
      @apply
         absolute
         bg-secondary-500
         w-8/12
         left-0;
         content: '';
         height: .06rem;
         bottom: -.5rem;
         transition: all 250ms ease-in-out;
   }
   &:active:before, &:hover:before {
      width:120%;
   }
   span {
      @apply
      inline-block;
      transition: all 250ms ease-in-out;
  }
  &:hover {
     @apply
     no-underline;
  }
}

.button.text-white {
   &:before {
      @apply
         bg-white;
   }
}

.button.text-white.center {
   &:before {
     left: 18%;
   }
   &:active:before, &:hover:before {
      left: -10%;
   }
}

.button-primary {
   @apply
   relative
   inline-block
   py-3
   px-8
   mt-3
   z-10
   rounded-full
   flex-shrink-0
   overflow-hidden
   font-normal
   bg-secondary-500
   text-white
   leading-tight;
   transition: all 250ms ease-in-out;
   &:hover {
      @apply
         bg-secondary-600
         no-underline;
   }
   &.text-white {
      @apply
      bg-white
      text-secondary-500;
      &:hover {
         @apply
            bg-gray-100;
      }
   }
}

