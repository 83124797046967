.countdown-timer {
    $block: &;
    @apply hidden max-w-md mx-auto mt-2 mb-8 bg-green-500 text-white rounded-3xl;

    &--white {
        @apply bg-white text-black;

        #{$block}__days, #{$block}__hours, #{$block}__minutes {
            @apply border-black border-opacity-25;
        }

        #{$block}__days:after, #{$block}__hours:after, #{$block}__minutes:after, #{$block}__seconds:after {
            @apply text-black text-opacity-25;
        }
    }

    &.init {
        @apply flex;
    }

    & > div {
        @apply w-1/4 text-center text-3xl font-bold pt-3 pb-4;
        line-height: 1;
        line-height: 1;

        @screen md {
            @apply text-5xl;
        }
    }

    &__days, &__hours, &__minutes {
        @apply border-r border-green-400;
    }

    &__days:after, &__hours:after, &__minutes:after, &__seconds:after {
        @apply block mt-1 text-sm text-green-200 font-normal;

        @screen md {
            @apply text-xl;
        }
    }

    &__days:after {
        content: "Days";
    }

    &__hours:after {
        content: "Hours";
    }

    &__minutes:after {
        content: "Minutes";
    }

    &__seconds:after {
        content: "Seconds";
    }
}
