.js-nav-bar {
    transition: all 250ms ease-in-out;
    background-color: rgba(255, 255, 255, 0);
}

.sticky-header {
    @apply bg-white;
    transition: all 250ms ease-in-out;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
        .logo {
            @apply
            opacity-0;
            transition: all 250ms ease-in-out;
        }
        .menu__links {
            @apply
            text-gray-900;
            transition: all 250ms ease-in-out;
        }
        .menu__burger {
            @apply
            text-gray-900;
            transition: all 250ms ease-in-out;
            transition-delay: 100ms;
        }
        .header--reviews {
            @apply
            inline-flex
            opacity-100;
        }
}
