.section {
   @apply
      inline-block
      align-top
      w-full
      relative;
	&__container {
      @apply
         relative
         w-full
         h-full;
      max-width: rem(1600);
		padding: {
         left: 8vw;
			right: 8vw;
		};
		@media (min-width: #{rem(1800)}) {
			@apply mx-auto;
		}
	}
}

