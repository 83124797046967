.whatsapp-badge {
    @apply fixed right-0 bottom-0 z-50 p-2 rounded-full text-white block;
    width: 36px;
    bottom: 56px;
    right: 10px;
    background: #65d072;

    @media (min-width: 416px) {
        width: 46px;
        bottom: 66px;
    }
}
